<ng-container *ngTemplateOutlet="titleTemplate; context: {$implicit: ordersData}"></ng-container>
<p-carousel [value]="chunkedArray" [numVisible]="carouselConfig.numVisible" [numScroll]="carouselConfig.numScroll"
  orientation="vertical" [circular]="true" [showNavigators]="false" [page]="realPage"
  [verticalViewPortHeight]="carouselConfig.verticalViewPortHeight" (onPage)="onCarouselPage($event)" [showIndicators]="false">
  <!-- [autoplayInterval]="carouselConfig.autoplayInterval" synced by parent component instead -->
  <ng-template let-item pTemplate="item">
    <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, atLeastOneIsUrgent: atLeastOneIsUrgent}">
    </ng-container>
  </ng-template>
</p-carousel>
<div class="p-carousel-more" *ngIf="displayMore"> ... </div>
