import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export function DateFormatValidator(format: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateValue = control.value;

    if (dateValue) {
      try {
        const luxon = DateTime.fromFormat(dateValue, format);
        if (!luxon.isValid) {
          throw new Error('invalid format');
        }
      } catch (error) {
        return { dateFormat: { valid: false } };
      }
    }
    return null;
  };
}
