import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Product } from '../models/product';
import { ProductStock } from '../models/product-stock';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  stocksObs: { [productSku: string]: Observable<ProductStock[]> } = {};

  constructor(private http: HttpClient, private toastMessagesService: ToastMessagesService) {}

  getBySku(productSku: string): Observable<Product> {
    return this.http
      .get<Product>(`${environment.apiURL}/products/${productSku}`)
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getStocks(productSku: string, merchantId: string, fromCache = false): Observable<ProductStock[]> {
    if (!this.stocksObs[productSku] || !fromCache) {
      this.stocksObs[productSku] = this.http
        .get<ProductStock[]>(`${environment.apiURL}/products/${productSku}/stocks`, {
          params: {
            realtime: true,
            organization: merchantId, //ER-1025
          },
        })
        .pipe(
          tap({ error: (error) => this.toastMessagesService.httpError(error) }),
          shareReplay(1),
        );
    }
    return this.stocksObs[productSku];
  }
}
