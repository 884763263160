<div class="p-dialog-header pr-0">
  <div class="d-flex">
    <div *ngIf="stepNumber !== 0" class="delivery-step">{{ stepNumber + '/2' }}</div>
    <span class="p-dialog-title">{{ header | translate }}</span>
  </div>
  <div class="mr-3">
    <ng-container *ngIf="stepNumber === 2">
      {{ 'order-details.header.client-order' | translate: { orderId: orderExternalIdentifier } }}
    </ng-container>
  </div>
  <div class="p-dialog-header-icons">
    <button
      (click)="close()"
      type="button"
      class="p-dialog-header-icon p-dialog-header-maximize p-link"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <ng-container *ngIf="stepNumber === 1; else clientSignature">
    <app-boarding-pass-component
      (scannerResultEvent)="receiveBoardingPassInfo($event)"
      (scannerBypassEvent)="bypassBoardingPassReceiver()"
      (redoScanEvent)="disableNextButton()"
      [bypassScan]="bypass"
      [flightInfo]="flightInfo"
      [orderExternalIdentifier]="orderExternalIdentifier"
    ></app-boarding-pass-component>
  </ng-container>
</div>
<div class="p-dialog-footer d-flex justify-content-between">
  <button
    [disabled]="disableBackBtn"
    [hidden]="target === Target.VAP"
    pButton
    type="button"
    class="p-button-outlined"
    (click)="back(); disableNextButton()"
  >
    {{ 'dialog.general-delivery.buttons.back' | translate }}
  </button>

  <div class="ml-a">
    <button
      [disabled]="disableCancelBtn"
      pButton
      (click)="openCancelValidation()"
      class="p-button-outlined to-cancel-button"
    >
      {{ 'ctas.cancel-order' | translate }}
    </button>
    <button
      pButton
      *ngIf="stepNumber === 1"
      type="button"
      (click)="openClientSignature()"
      [disabled]="disableNextBtn"
    >
      {{ 'dialog.general-delivery.buttons.next' | translate }}
    </button>

    <button
      [disabled]="disableDeliveryBtn"
      pButton
      *ngIf="stepNumber !== 1"
      type="button"
      (click)="delivery()"
    >
      {{ 'dialog.general-delivery.buttons.delivery' | translate }}
    </button>
  </div>
</div>

<ng-template #clientSignature>
  <app-signature-component
    [passenger]="clientInfo.passenger"
    [boardingPass]="boardingPassInfo"
    [orderId]="orderId"
    [orderIsPaid]="orderIsPaid"
    [target]="target"
    (onDisableDeliveryBtn)="
      disableDeliveryBtn = $event; disableCancelBtn = false; disableBackBtn = false
    "
    [bypassBoardingPass]="bypass"
    #signature
  ></app-signature-component>
  <button
    pButton
    type="button"
    class="reset-signature"
    (click)="signature.resetSignature()"
    [label]="'dialog.general-delivery.buttons.reset' | translate"
  ></button>
</ng-template>
