import { DateTime } from 'luxon';

export class DateTimeUtils {
  static timeDiffNow(isoDate: string): { hours?: number; minutes?: number } {
    // isoDate = '2022-02-28T20:30:23+01:00'; // keep for styling
    if (!isoDate) {
      return {};
    }
    const dateTime = DateTime.fromISO(isoDate);
    const diff = dateTime.diffNow(['hours', 'minutes', 'seconds']).toObject();
    if ((diff.hours ?? 0) > 2) {
      diff.minutes = 0;
    }
    return diff;
  }

  static isExpired(isoDate: string): boolean {
    const diff = DateTimeUtils.timeDiffNow(isoDate);
    return (diff.hours ?? -1) <= 0 && (diff.minutes ?? -1) <= 0;
  }

  static isToday(isoDate: string): boolean {
    if (!isoDate) {
      return false;
    }
    const date = DateTime.fromISO(isoDate);
    const today = DateTime.now().startOf('day');
    // (Higher-order units must also be identical for this function to return true)
    return date.hasSame(today, 'day');
  }

  static format(date: Date, format: string): string {
    if (!date) {
      return '';
    }
    return DateTime.fromJSDate(date).toFormat(format);
  }
}
