<div class="p-dialog-header">
  <span class="p-dialog-title">{{'dialog.reset-tcpos.title'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<div class="p-dialog-content">
  <p>{{'dialog.reset-tcpos.text'|translate}}</p>
</div>
<div class="p-dialog-footer ctas">
  <button pButton type="button" (click)="confirm()">
    {{'dialog.reset-tcpos.cta-confirm'|translate}}
  </button>
</div>
