<div class="layout-news-active">
  <p-table *ngIf="order" [value]="data" scrollHeight="440px" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>
          {{
            'dialog.general-delivery.boarding-pass-table.headers.client-info'
              | translate
              | uppercase
          }}
        </th>
        <th
          *ngIf="
            order.target !== Target.VAP &&
            !(
              order.orderChannel === Channel.M2 &&
              order.orderPaymentMethod === PaymentMethod.CLICK_AND_RESERVE
            )
          "
        >
          {{
            'dialog.general-delivery.boarding-pass-table.headers.boarding-pass-info'
              | translate
              | uppercase
          }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [ngClass]="isError(row)">
        <td class="text-left bold border-left-white">
          <p>{{ 'order-details.description.' + row | translate }}</p>
        </td>
        <td class="text-left border-left-white">
          <container-element [ngSwitch]="row">
            <p *ngSwitchCase="'title'">
              {{ 'common.civility.' + order.orderCustomerJsonData.gender | translate }}
            </p>
            <p *ngSwitchCase="'customer'">
              {{ order.orderCustomerJsonData.first_name }}
              {{ order.orderCustomerJsonData.last_name }}
            </p>
            <p *ngSwitchCase="'phone'">
              {{ order.orderCustomerJsonData.phone_number }}
            </p>
            <p *ngSwitchCase="'company-name'">
              {{
                order.target === Target.PAX
                  ? order.orderFlightInfos[0]?.extendedData?.initial_flight?.airline_name
                  : order.orderCustomerJsonData.company
              }}
            </p>
            <ng-container *ngIf="order.orderIsPaid !== null">
              <p *ngSwitchCase="'order-is-paid'">
                {{
                  (order.orderIsPaid === true
                    ? 'order-details.description.is-paid'
                    : 'order-details.description.is-not-paid'
                  ) | translate
                }}
              </p>
            </ng-container>
            <p *ngSwitchCase="'passenger'">
              {{ order.orderCustomerJsonData.first_name }}
              {{ order.orderCustomerJsonData.last_name }}
            </p>
            <p *ngSwitchCase="'name'">
              {{ order.orderCustomerJsonData.first_name }}
              {{ order.orderCustomerJsonData.last_name }}
            </p>

            <p *ngSwitchCase="'order-type'">
              {{ order.orderType }}
            </p>
            <p *ngSwitchCase="'channel'">
              {{ order.orderChannel }}
            </p>
            <p *ngSwitchCase="'sale'">
              {{ order?.orderVapInfos?.saleName }}
            </p>

            <p *ngSwitchCase="'sale-date'">
              {{ order?.orderVapInfos?.saleStartDate | date: 'dateInput' }} -
              {{ order?.orderVapInfos?.saleEndDate | date: 'dateInput' }}
            </p>

            <p *ngSwitchCase="'payment-method'">
              {{ 'order-details.description.' + order?.orderPaymentMethod | translate }}
            </p>

            <ng-container *ngIf="order.orderType === Target.PAX">
              <p *ngSwitchCase="'flight-date'">
                <ng-container *ngIf="order.orderFlightInfos.length > 0">
                  {{ order.orderFlightInfos[0].departureDateTime | date }} -
                  {{ order.orderFlightInfos[0].departureDateTime | time }}
                </ng-container>
              </p>
              <p *ngSwitchCase="'destination-airport'">
                <ng-container *ngIf="order.orderFlightInfos.length > 0">
                  {{ order.orderFlightInfos[0].arrivalAirportIataCode }}
                </ng-container>
              </p>
              <p *ngSwitchCase="'origin-airport'">
                <ng-container *ngIf="order.orderFlightInfos.length > 0">
                  {{ order.orderFlightInfos[0].departureAirportIataCode }}
                </ng-container>
              </p>
              <p *ngSwitchCase="'flight-number'">
                <ng-container *ngIf="order.orderFlightInfos.length > 0">
                  <app-flight-number-and-gate
                    [flightInfo]="order.orderFlightInfos[0]"
                  ></app-flight-number-and-gate>
                </ng-container>
              </p>
              <p *ngSwitchCase="'nationality'">
                {{ 'common.nationality.french' | translate }}
              </p>
              <p *ngSwitchCase="'transit'">
                {{ 'common.transit.no' | translate }}
              </p>
              <p *ngSwitchCase="'tax'">
                {{ 'common.tarification.' + order.orderPricelistApplied | translate }}
              </p>
            </ng-container>
          </container-element>
        </td>
        <td
          *ngIf="
            order.target === Target.PAX &&
            !(
              order.orderChannel === Channel.M2 &&
              order.orderPaymentMethod === PaymentMethod.CLICK_AND_RESERVE
            )
          "
        >
          <ng-container *ngIf="order.bypassBoardingPass === true && this.isComparable(row)">
            <p>{{ 'scan_error' | translate }}</p>
          </ng-container>

          <ng-container *ngIf="order.bypassBoardingPass === false">
            <container-element [ngSwitch]="row">
              <p *ngSwitchCase="'passenger'">
                {{ boardingPass?.passenger }}
              </p>
              <p *ngSwitchCase="'flight-date'">
                <ng-container *ngIf="boardingPass?.departureDateTime">
                  {{ boardingPass.departureDateTime | date }}
                </ng-container>
              </p>
              <p *ngSwitchCase="'destination-airport'">
                {{ boardingPass?.arrivalAirportIataCode }}
              </p>
              <p *ngSwitchCase="'origin-airport'">
                {{ boardingPass?.departureAirportIataCode }}
              </p>
              <p *ngSwitchCase="'vol'">
                {{ boardingPass?.flightNumber }}
              </p>
              <p *ngSwitchCase="'transit'">
                {{
                  (boardingPass?.transit ? 'common.transit.yes' : 'common.transit.no') | translate
                }}
              </p>
              <p *ngSwitchCase="'tax'">
                <ng-container *ngIf="boardingPass?.taxRegime">
                  {{ 'common.tarification.' + boardingPass?.taxRegime?.toUpperCase() | translate }}
                </ng-container>
              </p>
              <p *ngSwitchDefault></p>
            </container-element>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
