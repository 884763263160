<div class="p-dialog-header">
  <span class="p-dialog-title" *ngIf="!force">{{'dialog.confirm-delivery.title'|translate}}</span>
  <span class="p-dialog-title" *ngIf="force">{{'dialog.confirm-delivery.title-force'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<div class="p-dialog-content" *ngIf="!force">
  <p>{{'dialog.confirm-delivery.text'|translate}}</p>
</div>
<div class="p-dialog-content" *ngIf="force">
  <p>{{'dialog.confirm-delivery.text-force'|translate}}</p>
</div>
<div class="p-dialog-footer ctas">
  <button pButton type="button" (click)="close()" class="p-button-link" *ngIf="!force">
    {{'dialog.confirm-delivery.cta-close'|translate}}
  </button>
  <button pButton type="button" (click)="confirm()" *ngIf="!force">
    {{'dialog.confirm-delivery.cta-confirm'|translate}}
  </button>
  <button pButton type="button" (click)="confirm()" *ngIf="force">
    {{'dialog.confirm-delivery.cta-confirm-force'|translate}}
  </button>
</div>
