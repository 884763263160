export class ArrayUtil {
  // source: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  static equal(a: any[], b: any[]): boolean {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  static findAndRemove(array: any[], find: ((el: any) => boolean) | any): void {
    if (!Array.isArray(array) || !array.length) {
      return;
    }
    if (Array.isArray(find)) {
      return find.forEach((el) => ArrayUtil.findAndRemove(array, el));
    }
    const fn = typeof find === 'function' ? find : (el: any) => el === find;
    const index = array.findIndex(fn);
    array.splice(index, 1);
  }

  static getLast(array: any[]): any {
    if (!array || !array.length) {
      return;
    }
    return array[array.length - 1];
  }

  static groupByCount<T>(array: T[], count: number): T[][] {
    if (!array || !array.length) {
      return [];
    }
    const nbChunks = Math.ceil(array.length / count);
    const chunkedArray: any[] = [];
    [...Array(nbChunks).keys()].forEach((nb) => {
      const nbM = nb * count;
      chunkedArray.push(array.slice(nbM, Math.min(nbM + count, array.length)));
    });
    return chunkedArray;
  }
}
