import { Injectable } from '@angular/core';

import { Channel } from '../constants';
import { isCancelled, isToCancel, OrderStatus } from '../constants';
import { CancellationReasons } from '../constants/cancellation-reasons';
import { PaymentMethod } from '../constants/payment-method';
import { Order } from '../models';
import { OrderCTA } from '../models/order-cta';
import { OrderState } from '../models/order-state';

@Injectable({
  providedIn: 'root',
})
export class OrderStateService {
  getOrderStateAndCTA(order: Order): { cta: OrderCTA; adminCta?: OrderCTA[]; state: OrderState } {
    if (order.orderChannel === Channel.ADR && order.orderStatus === OrderStatus.NEW) {
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.prepare',
            link: `/order/${order.orderId}/prepare`,
            queryParams: { action: 'prepare' },
          },
          details: {
            label: 'ctas.start-preparation',
            action: 'start',
          },
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'ctas.cancel-order',
              action: 'cancel',
            },
          },
        ],
      };
    } else if (order.orderStatus === OrderStatus.NEW) {
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.prepare',
            link: `/order/${order.orderId}/prepare`,
            queryParams: { action: 'prepare' },
          },
          details: {
            label: 'ctas.start-preparation',
            action: 'start',
          },
        },
      };
    } else if ([OrderStatus.IN_PROGRESS].includes(order.orderStatus)) {
      return {
        state: {
          canPick: true,
          canCancel: true,
        },
        cta: {
          list: {
            label: 'ctas.continue',
            link: `/order/${order.orderId}`,
            queryParams: { action: 'prepare' },
          },
          details: {
            label: 'ctas.finish-preparation',
            action: 'finish',
            viewLabel: 'ctas.continue-preparation',
          },
        },
      };
    } else if ([OrderStatus.WAITING_FOR_RESTOCK].includes(order.orderStatus)) {
      return {
        state: {
          canPick: true,
          canCancel: true,
        },
        cta: {
          list: {
            label: 'ctas.continue',
            link: `/order/${order.orderId}/prepare`,
          },
          details: {
            label: 'ctas.finish-preparation',
            action: 'finish',
            viewLabel: 'ctas.continue-preparation',
          },
        },
      };
    } else if ([OrderStatus.READY_TO_CLOSE].includes(order.orderStatus)) {
      //  no user action, just wait for automatic status update
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.view',
            link: `/order/${order.orderId}`,
          },
          details: {
            label: 'order-details.prepare.pos-registration',
          },
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'order-details.prepare.pos-registration-reset',
              action: 'reset-tcpos',
            },
          },
          {
            list: {},
            details: {
              label: 'order-details.prepare.force-cancel',
              action: 'force-cancel',
            },
          },
        ],
      };
    } else if (order.orderStatus === OrderStatus.READY_FOR_COLLECT) {
      // if editing this condition, also check the opening condition in openDeliveryDialog()
      if (
        order.orderChannel === Channel.ADR ||
        order.orderPaymentMethod === PaymentMethod.CLICK_AND_RESERVE
      ) {
        return {
          state: {
            canPick: false,
            canCancel: false,
          },
          cta: {
            list: {
              label: 'ctas.view',
              link: `/order/${order.orderId}`,
            },
            details: {},
          },
          adminCta: [
            {
              list: {},
              details: {
                label: 'order-details.prepare.pos-registration-reset',
                action: 'reset-tcpos',
              },
            },
            {
              list: {},
              details: {
                label: 'order-details.prepare.force-cancel',
                action: 'force-cancel',
              },
            },
          ],
        };
      }

      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.deliver',
            link: `/order/${order.orderId}`,
          },
          details: {
            label: 'ctas.deliver',
            action: 'deliver',
          },
        },
      };
    } else if (order.orderStatus === OrderStatus.COLLECTED) {
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.view',
            link: `/order/${order.orderId}`,
          },
          details: {},
        },
      };
    } else if (isCancelled(order.orderStatus)) {
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {
            label: 'ctas.view',
            link: `/order/${order.orderId}`,
          },
          details: {},
        },
      };
    } else if (order.orderStatus === OrderStatus.TO_CANCEL_FROM_READY_FOR_COLLECT) {
      return {
        state: {
          canPick: false,
          canCancel: false, // use main CTA instead
        },
        cta: {
          list: {
            label: 'ctas.cancel',
            link: `/order/${order.orderId}`,
            queryParams: { action: 'cancel' },
          },
          details: {
            label: 'ctas.cancel-order',
            action: 'cancel',
          },
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'ctas.force-to-collect',
              action: 'force-to-collect',
            },
          },
          {
            list: {},
            details: {
              label: 'order-details.prepare.force-cancel',
              action: 'force-cancel',
            },
          },
        ],
      };
    } else if (
      isToCancel(order.orderStatus) &&
      order.orderStatusMessage !== CancellationReasons.NOSHOW
    ) {
      return {
        state: {
          canPick: false,
          canCancel: false, // use main CTA instead
        },
        cta: {
          list: {
            label: 'ctas.cancel',
            link: `/order/${order.orderId}`,
            queryParams: { action: 'cancel' },
          },
          details: {
            label: 'ctas.cancel-order',
            action: 'cancel',
          },
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'order-details.prepare.force-cancel',
              action: 'force-cancel',
            },
          },
        ],
      };
    } else if (
      isToCancel(order.orderStatus) &&
      order.orderStatusMessage === CancellationReasons.NOSHOW
    ) {
      return {
        state: {
          canPick: false,
          canCancel: false, // use main CTA instead
        },
        cta: {
          list: {
            label: 'ctas.cancel',
            link: `/order/${order.orderId}`,
            queryParams: { action: 'cancel' },
          },
          details: {
            label: 'ctas.cancel-order',
            action: 'cancel',
          },
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'ctas.force-deliver',
              action: 'force-deliver',
            },
          },
          {
            list: {},
            details: {
              label: 'order-details.prepare.force-cancel',
              action: 'force-cancel',
            },
          },
        ],
      };
    } else if (
      [OrderStatus.PENDING_COLLECTED_STEP1, OrderStatus.PENDING_COLLECTED_STEP2].includes(
        order.orderStatus,
      )
    ) {
      return {
        state: {
          canPick: false,
          canCancel: false,
        },
        cta: {
          list: {},
          details: {},
        },
        adminCta: [
          {
            list: {},
            details: {
              label: 'ctas.force-collected',
              action: 'force-collected',
            },
          },
        ],
      };
    }

    return {
      state: {
        canPick: false,
        canCancel: false,
      },
      cta: {
        list: {
          label: 'ctas.view',
          link: `/order/${order.orderId}`,
        },
        details: {},
      },
    };
  }
}
