export enum GateStatus {
  none = 'none', // aucun appel api n’a été fait
  na = 'N.C.', // appel api fait mais la porte n’est pas encore disponible
  available = 'available', // appel api fait et la porte est disponible
  has_changed = 'has_change', // appel api déjà fait, mais la porte a changé
  ko = 'KO', // le dernier appel api a provoqué une erreur (mais on peut tout de même avoir récupérer la porte sur un appel précedent
}

export interface FlightInfo {
  departureAirportIataCode: string; //'NIC'
  arrivalAirportIataCode: string; //'DUB'
  departureDateTime: string; //'2021-10-22T11:00:00.000000+02:00'
  flightNumber: string; //'EM345'
  companyIataCode: string;
  extendedData: ExtendedFlightData;
  gate: string; // ABC
  gateStatus: GateStatus;
}

export interface ExtendedFlightData {
  initial_flight: ExtendedFlightInfo | null;
  final_flight: ExtendedFlightInfo | null;
}

export interface ExtendedFlightInfo {
  final_flight: string;
  departure_iata_code: string;
  arrival_iata_code: string;
  departure_terminal: string;
  display_flight_number: string;
  airline_name: string;
  date: Date;
  departure_date: string;
  departure_scheduled_time: string;
  flight_number: string;
  airline_code: string;
  alternate_flight_number: string;
}

export interface FlightInfoExternal {
  airline_code: string;
  alternate_flight_number: string;
  arrival_iata_code: string;
  composite_key: string;
  date: Date; // "2023-12-31T09:56:00+01:00"
  departure_date: string;
  departure_iata_code: string;
  departure_scheduled_tim: string;
  departure_terminal: string;
  display_flight_number: string;
  flight_number: string;
  status: string;
}
