import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { DATE_TIME_FORMATS } from '../constants/datetime-formats';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (!value) {
      return '';
    }
    const dateTime = DateTime.fromISO(value.replace(' ', 'T'));
    const format =
      DATE_TIME_FORMATS.time[(this.translateService.currentLang as 'en' | 'fr') ?? 'en'];
    return dateTime.setLocale(this.translateService.currentLang).toFormat(format);
  }
}
