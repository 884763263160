import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivateChild {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Redirect to the home page if not admin
    return this.authenticationService.isAdmin() || this.router.parseUrl('/');
  }
}
