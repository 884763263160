<ng-container *ngIf="order.orderPreparationCompleted">
  {{'order-details.deadline.done'|translate}}<br>
  {{order.orderPreparationCompleted|date}}
</ng-container>
<ng-container *ngIf="!order.orderPreparationCompleted">
  <ng-container *ngIf="expired">
    {{'order-details.deadline.expired'|translate}}
  </ng-container>
  <span [class.is-urgent]="isUrgent" *ngIf="!expired && diff">
    <ng-container *ngIf="(diff.days ?? -1) > 0">
      {{'common.nb-days'| pluralTranslate:(diff.days) | translate: diff }}
    </ng-container>
    <ng-container *ngIf="diff.days === 0 && (diff.hours ?? -1) > 1">
      {{'common.nb-hours'|translate: diff }}
    </ng-container>
    <ng-container *ngIf="diff.days === 0 && (diff.hours ?? 3) == 1">
      {{'common.nb-hours-minutes'|translate: {hours: diff.hours, minutes: (diff.minutes|twoDigits)} }}
    </ng-container>
    <ng-container *ngIf="diff.days === 0 && (diff.hours ?? 3) < 1">
      {{'common.nb-minutes'|translate: diff }}
    </ng-container>
  </span>
</ng-container>
