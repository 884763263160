import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-preparation-not-finished-dialog',
  templateUrl: './preparation-not-finished-dialog.component.html',
  styleUrls: ['./preparation-not-finished-dialog.component.scss'],
})
export class PreparationNotFinishedDialogComponent {
  constructor(public ref: DynamicDialogRef) {}

  close(): void {
    this.ref.close(null);
  }
}
