export enum OrderlineStatus {
  NEW = '1|NEW',
  WAITING_FOR_RESTOCK = '2|WAITINGFORRESTOCK',
  FULFILLED = '3|FULFILLED',
  CANCELLED = '4|CANCELLED',
  RETURNED = '5|RETURNED',
  DELIVERED = '6|DELIVERED',
  ERROR = '7|ERROR',
  PARTIAL = '8|PARTIAL',
}
