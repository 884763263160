<div class="p-dialog-header">
  <span class="p-dialog-title">{{ title | translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>

<div class="p-dialog-content">
  <p>{{content | translate}}</p>
</div>

<div class="p-dialog-footer ctas">
  <button pButton type="button" class="p-button-outlined" (click)="close()">
    {{ labelCancelButton|translate }}
  </button>
  <button pButton type="button" (click)="confirm()">
    {{ labelOkButton | translate }}
  </button>
</div>
