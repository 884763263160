import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RO } from 'src/app/models/ro';
import { HistoryService } from 'src/app/services/history.service';
import { RoService } from 'src/app/services/ro.service';

@Component({
  selector: 'app-ol-details',
  templateUrl: './ol-details.component.html',
  styleUrls: ['./ol-details.component.scss'],
})
export class OlDetailsComponent {
  ol: RO;

  constructor(
    private activatedRoute: ActivatedRoute, // private router: Router,
    private roService: RoService,
    private historyService: HistoryService,
  ) {
    const id = this.activatedRoute.snapshot.params.id;
    this.roService.getById(id).subscribe((ol) => {
      this.ol = ol;
    });
  }

  back() {
    this.historyService.goBackToOrdersList();
  }
}
