<div class="p-dialog-header">
  <span *ngIf="!auto || force" class="p-dialog-title">{{'dialog.cancel-order.title-warning'|translate}}</span>
  <span *ngIf="auto && !force" class="p-dialog-title">{{'dialog.cancel-order.title'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>


<div *ngIf="!force" class="p-dialog-content">
  <p *ngIf="!auto">{{'dialog.cancel-order.text-warning'|translate}}</p>
  <p *ngIf="auto">{{'dialog.cancel-order.reasons.' + order.orderStatusMessage | translate: {orderExternalIdentifier:
    order.orderExternalIdentifier} }}</p>
</div>

<div *ngIf="force" class="p-dialog-content">
  <p>{{'dialog.cancel-order.text-warning-force'|translate}}</p>
</div>

<div *ngIf="!force" class="p-dialog-footer ctas">
  <button pButton type="button" class="p-button-outlined" (click)="close()">
    {{'dialog.cancel-order.postpone'|translate}}
  </button>
  <button *ngIf="!auto" pButton type="button" (click)="cancelOrder()">
    {{'dialog.cancel-order.cancel-cta-manual'|translate}}
  </button>
  <button *ngIf="auto" pButton type="button" (click)="cancelOrder()">
    {{ 'dialog.cancel-order.cancel-cta-1'|translate }}
  </button>
</div>

<div *ngIf="force" class="p-dialog-footer ctas reverse">
  <button pButton type="button" (click)="forceCancelOrder()">
    {{ 'dialog.cancel-order.cancel-cta-1-force'|translate}}
  </button>
</div>
