export enum Transition {
  TRANS_TO_PENDING_READY_TO_CLOSE_51 = 'trans_to_pending_ready_to_close_51',
  TRANS_TO_PENDING_READY_TO_CLOSE_61 = 'trans_to_pending_ready_to_close_61',
  TRANS_TO_PENDING_READY_TO_CLOSE_STEP_2 = 'trans_to_pending_ready_to_close_step_2',
  TRANS_TO_READY_TO_CLOSE = 'trans_to_ready_to_close',
  TRANS_TO_READY_FOR_COLLECT_71 = 'trans_to_ready_for_collect_71',
  TRANS_TO_CANCELLED_81 = 'trans_to_cancelled_81',
  TRANS_TRY_TO_CANCELLED_91 = 'trans_try_to_cancelled_91',
  TRANS_TO_CANCELLED_102 = 'trans_to_cancelled_102',
  TRANS_IFT_TO_READY_FOR_COLLECT_21 = 'trans_ift_to_ready_for_collect_21',
}
