<div class="main">
  <p class="date">{{ now | date: 'dateTimeShort2' }}</p>
  <div class="main-orders-off">
    <app-lazy-chunked-carousel
      [carouselConfig]="carouselConfigMain"
      [refreshWhen]="refreshObs"
      [requestParams]="requestParamsNew"
      (dataReceived)="onDataReceived()"
      [displaySync]="displaySync"
      [page]="page"
    >
      <ng-template let-data pTemplate="title">
        <h2 class="title">
          {{ 'remote-display.incoming-orders' | translate: { nb: data?.meta.totalItems ?? '' } }}
        </h2>
      </ng-template>
      <ng-template let-orders pTemplate="item" let-urgent="atLeastOneIsUrgent">
        <div class="main-orders" [class.expired_soon]="urgent">
          <div class="main-order" *ngFor="let order of orders">
            <ng-container *ngIf="order">
              <p class="main-order-id">#{{ order.orderExternalIdentifier }}</p>
              <p>{{ order.orderDeadlinePreparation | date }}</p>
              <p>{{ order.orderDeadlinePreparation | time }}</p>
              <div
                class="remaining-time order-cell_status"
                [class.order-status-to_cancel]="order.orderPreparationIsDelayed"
              >
                <app-time-from-now
                  class="customer-badge"
                  [order]="order"
                  [useDeadlinePreparation]="true"
                ></app-time-from-now>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-lazy-chunked-carousel>
  </div>
</div>

<div class="side">
  <div
    class="block-to-cancel"
    [ngStyle]="{ visibility: (nbOrdersToCancel$ | async) ? 'visible' : 'hidden' }"
  >
    {{ 'remote-display.to-cancel' | translate: { nb: nbOrdersToCancel$ | async } }}
  </div>

  <div class="block-side">
    {{ '' // Temporary disable the carousel by setting [page]="1" }}
    <app-lazy-chunked-carousel
      [carouselConfig]="carouselConfigSide"
      [refreshWhen]="refreshObs"
      [requestParams]="requestParamsIncomingCollect"
      (dataReceived)="onDataReceived()"
      [displaySync]="displaySync"
      [page]="1"
    >
      <ng-template let-data pTemplate="title">
        <h2 class="title">
          {{ 'remote-display.incoming-collect' | translate: { nb: data?.meta.totalItems ?? '' } }}
        </h2>
      </ng-template>
      <ng-template let-orders pTemplate="item">
        <ng-container *ngIf="orders.length">
          <p-table #table [value]="orders">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  {{ 'orders.columns.client_order' | translate }}
                </th>
                <th>{{ 'remote-display.day' | translate }}</th>
                <th>{{ 'remote-display.time' | translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
              <tr [class.promoted]="order?.orderIsPromoted">
                <td class="order-cell_id">
                  {{ order?.orderExternalIdentifier }}
                </td>
                <td class="order-cell_customer-period">
                  {{
                    order?.target === Target.PAX
                    ? (order?.orderFlightInfos[0]?.departureDateTime | date)
                    : (order?.orderDeadlinePreparation | date)
                  }}
                </td>
                <td class="order-cell_customer-period">
                  {{
                    order?.target === Target.PAX
                    ? (order?.orderFlightInfos[0]?.departureDateTime | time)
                    : (order?.orderDeadlinePreparation | time)
                  }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </ng-template>
    </app-lazy-chunked-carousel>
  </div>
  <div class="block-side" *ngIf="(incomingRo$ | async)?.items?.length">
    <h2 class="title">
      {{
        'remote-display.incoming-ro'
          | translate: { nb: (incomingRo$ | async)?.meta?.totalItems ?? '' }
      }}
    </h2>
    <p-table [value]="(incomingRo$ | async)?.items ?? []">
      <ng-template pTemplate="header">
        <tr>
          <th>
            {{ 'remote-display.ro' | translate }}
          </th>
          <th>{{ 'remote-display.date' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ro>
        <tr>
          <td class="order-cell_id">
            {{ ro?.replenishmentSAPCode }}
          </td>
          <td class="order-cell_customer-perdiod">
            {{ ro?.createdOn | date }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
