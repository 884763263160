import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appPreventDoubleClick]',
  host: {
    '[attr.disabled]': 'disabled || null',
    '[class.p-disabled]': 'disabled',
  },
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
  debounceTime = 500;
  disabled: boolean;
  _disabledInput: boolean;

  @Input()
  set disabledInput(isDisabled: boolean) {
    this._disabledInput = isDisabled;
    this.disabled = Boolean(isDisabled);
  }
  get disabledInput(): boolean {
    return this._disabledInput;
  }

  @Output()
  debounceClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((e) => this.emitDebounceTimeClick(e));
  }

  emitDebounceTimeClick(e: unknown) {
    this.disabled = Boolean(this.disabledInput);
    this.debounceClick.emit(e);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: PointerEvent) {
    this.disabled = true;
    this.clicks.next(event);
  }
}
