<div class="p-dialog-header"></div>
<div class="p-dialog-content">
  <h3>{{ 'dialog.cancel-order.order-unpaid-text-warning' | translate }}</h3>
</div>

<div class="p-dialog-footer d-flex justify-content-between">
  <button pButton type="button" class="p-button-outlined" (click)="back();">
    {{ 'dialog.general-delivery.buttons.back' | translate }}
  </button>

  <div class="ml-a">
    <button pButton (click)="openCancelValidation()" class="p-button-outlined to-cancel-button">
      {{ 'ctas.cancel-order' | translate }}
    </button>
  </div>
</div>
