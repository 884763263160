import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-time-from-now',
  templateUrl: './time-from-now.component.html',
  styleUrls: ['./time-from-now.component.scss'],
})
export class TimeFromNowComponent implements OnChanges {
  @Input() order: Order;
  @Input() useDeadlinePreparation = false;
  expired = false;
  diff: { days?: number; hours?: number; minutes?: number };
  isUrgent: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order) {
      const dateTime = DateTime.fromISO(
        this.useDeadlinePreparation
          ? this.order.orderDeadlinePreparation
          : this.order.orderDeadlineCashout,
      );
      this.diff = dateTime.diffNow(['days', 'hours', 'minutes', 'seconds']).toObject();
      this.expired =
        (this.diff.days ?? -1) <= 0 &&
        (this.diff.hours ?? -1) <= 0 &&
        (this.diff.minutes ?? -1) <= 0;
      this.isUrgent = this.order.orderIsUrgent || this.order.orderIsVeryUrgent;
    }
  }
}
