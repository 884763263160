export const currencies: Currency[] = [
  {
    code: 'EUR',
    symbol: '€',
    position: 'after',
  },
  {
    code: 'USD',
    symbol: '$',
    position: 'before',
  },
];

interface Currency {
  code: string;
  symbol: string;
  position: 'before' | 'after';
}
