import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { OlDetailsComponent } from './components/ol/ol-details/ol-details.component';
import { OrderDetailsComponent } from './components/order/order-details/order-details.component';
import { RoDetailsComponent } from './components/ro/ro-details/ro-details.component';
import { HasJwtGuard } from './guard/has-jwt.guard';
import { IsAdminGuard } from './guard/is-admin.guard';

const routes: Routes = [
  {
    path: 'orders',
    loadChildren: () =>
      import('src/app/components/orders/orders.module').then((m) => m.OrdersModule),
    canActivateChild: [HasJwtGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('src/app/components/admin/admin.module').then((m) => m.AdminModule),
    canActivateChild: [IsAdminGuard],
  },
  {
    path: 'order/:id',
    component: OrderDetailsComponent,
  },
  {
    path: 'order/:id/prepare',
    component: OrderDetailsComponent,
    data: {
      pickMode: true,
    },
  },
  {
    path: 'ol/:id',
    component: OlDetailsComponent,
  },
  {
    path: 'ro',
    component: RoDetailsComponent,
  },
  {
    path: 'ro/:id',
    component: RoDetailsComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'remote',
    loadChildren: () =>
      import('src/app/components/remote-display/remote-display.module').then(
        (m) => m.RemoteDisplayModule,
      ),
  },
  { path: '', redirectTo: '/orders', pathMatch: 'full' },
  { path: '**', redirectTo: '/orders', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
