import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Target } from 'src/app/constants/target';
import { ClientInfo, TableValue } from 'src/app/models/client-info';

import { SignatureComponent } from './components/signature/signature.component';

@Component({
  selector: 'app-general-delivery-dialog',
  templateUrl: './general-delivery-dialog.component.html',
  styleUrls: ['./general-delivery-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralDeliveryDialogComponent implements OnInit {
  cancelValidationRef?: DynamicDialogRef;
  clientInfo: ClientInfo;
  flightInfo: TableValue<ClientInfo>[] = [
    { label: 'passenger', key: 'passenger' },
    { label: 'flight-date', key: 'flightDate' },
    { label: 'destination-airport', key: 'destinationAirport' },
    { label: 'origin-airport', key: 'originAirport' },
    { label: 'vol', key: 'vol' },
    { label: 'nationality', key: 'nationality' },
    { label: 'transit', key: 'transit' },
    { label: 'tax', key: 'tax' },
  ];
  boardingPassInfo: Partial<ClientInfo>;
  stepNumber = 0;
  header = '';
  target: string;
  Target = Target;
  orderId: string;
  orderExternalIdentifier: string;
  orderIsPaid: boolean | null;
  disableDeliveryBtn = true;
  disableCancelBtn = false;
  disableBackBtn = false;
  disableNextBtn = true;
  bypass = false;

  @ViewChild('signature') signature: SignatureComponent;

  constructor(private config: DynamicDialogConfig, public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    this.target = this.config.data.target;
    this.clientInfo = this.config.data.clientInfo;
    this.orderId = this.config.data.orderId;
    this.orderExternalIdentifier = this.config.data.orderExternalIdentifier;
    this.orderIsPaid = this.config.data.orderIsPaid;

    this.flightInfo.forEach((row) => {
      return (row.value = this.clientInfo[row.key]);
    });

    if (this.target === Target.PAX) {
      this.stepNumber = 1;
    }
    this.updateHeader();
  }

  close(): void {
    this.ref.close();
  }

  openCancelValidation(): void {
    this.ref.close('openCancelValidationDialog');
  }

  back(): void {
    if (this.stepNumber === 2) {
      this.bypass = false;
      this.stepNumber = 1;
      this.updateHeader();
      return;
    }
    this.close();
  }

  openClientSignature() {
    this.stepNumber = 2;
    this.updateHeader();
  }

  delivery() {
    this.disableDeliveryBtn = true;
    this.disableCancelBtn = true;
    this.disableBackBtn = true;
    this.signature.delivery();
  }

  disableNextButton() {
    this.disableNextBtn = true;
  }

  bypassBoardingPassReceiver() {
    this.bypass = true;
    this.disableNextBtn = true; // wait for form
  }

  receiveBoardingPassInfo(event: Partial<ClientInfo>): void {
    this.boardingPassInfo = event;
    this.boardingPassInfo.taxRegime = this.clientInfo.taxRegime;
    this.disableNextBtn = false;
  }

  private updateHeader() {
    if (this.target === Target.PAX) {
      this.header = `dialog.general-delivery.${
        this.stepNumber === 1 ? 'boarding-pass-title' : 'client-signature-title'
      }`;
    } else {
      this.header = 'dialog.general-delivery.client-signature-title';
    }
  }
}
