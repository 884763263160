import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';

import { AVAILABLE_LANGUAGES } from '../constants/constants';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public lang: string;

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  init() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        const lang = this.activatedRoute.snapshot.queryParams.lang;
        if (lang) {
          // lang forced from URL (e.g. lang=fr)
          this.useLang(lang, false);
        } else {
          // only when user logs in (not on page refresh)
          this.authenticationService.isLoggedIn.subscribe(() => {
            const userId = this.authenticationService.decodedToken.id;
            this.userService.get(userId).subscribe((user) => {
              if (user.language && AVAILABLE_LANGUAGES.includes(user.language)) {
                this.useLang(user.language, false);
              }
            });
          });
          this.authenticationService.isLoggedOut.subscribe(() => {
            this.storageService.delete('lang');
            this.setNavLang();
            this.translateService.use(this.lang);
          });
          this.translateService.setDefaultLang('en');
          this.setNavLang();
        }
      });
  }

  setNavLang(): void {
    let navLang = navigator.language.slice(0, 2);
    navLang = AVAILABLE_LANGUAGES.includes(navLang) ? navLang : 'en';
    this.lang = this.storageService.get('lang') ?? navLang ?? 'en';
    this.translateService.use(this.lang);
  }

  useLang(lang: string, updateUser = true): void {
    if (this.lang === lang) {
      return;
    }
    this.lang = lang;
    this.translateService.use(lang);
    this.storageService.set<string>('lang', lang);
    if (updateUser) {
      this.userService
        .update({
          id: this.authenticationService.decodedToken.id,
          language: lang,
        })
        .subscribe();
    }
  }

  getLangFull(): 'en_US' | 'fr_FR' {
    const mapping = {
      en: 'en_US',
      fr: 'fr_FR',
    };
    const userLang = this.translateService.currentLang as 'en' | 'fr';
    const lang = (mapping[userLang] ?? 'en_US') as 'en_US' | 'fr_FR';
    return lang;
  }
}
