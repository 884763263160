<div class="layout-news-active">
  <p-table [value]="data" scrollHeight="440px" responsiveLayout="scroll">
    <ng-template pTemplate="body" let-row>
      <tr>
        <td class="text-left bold border-left-white">
          <p>{{ 'order-details.pickup-point.' + row | translate }}</p>
        </td>
        <td class="text-left border-left-white">
          <container-element [ngSwitch]="row">
            <p *ngSwitchCase="'shipping-point-label'">
              <ng-container *ngIf="order.orderShippingPoint">
                {{ order?.orderShippingPoint }}
              </ng-container>
            </p>
            <p *ngSwitchCase="'collection-type'">
              {{ order.orderCollectionType }}
            </p>
            <p *ngSwitchCase="'flight-number'">
              <ng-container *ngIf="order.orderFlightInfos.length > 0">
                <app-flight-number-and-gate
                  [flightInfo]="order.orderFlightInfos[0]"
                ></app-flight-number-and-gate>
              </ng-container>
            </p>
            <p *ngSwitchCase="'flight-date'">
              <ng-container *ngIf="order.orderFlightInfos.length > 0">
                {{ order.orderFlightInfos[0].departureDateTime | date }} -
                {{ order.orderFlightInfos[0].departureDateTime | time }}
              </ng-container>
            </p>

            <p *ngSwitchCase="'collection-period'">
              {{ order.orderVapInfos?.withdrawalStartDate | date: 'dateInput' }} -
              {{ order.orderVapInfos?.withdrawalEndDate | date: 'dateInput' }}
            </p>

            <p *ngSwitchCase="'collection-date'">
              {{ order.orderVapInfos?.preparationEndDate | date: 'dateInput' }}
            </p>
          </container-element>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
