export enum OrderStatus {
  ALL = 'ALL',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_RESTOCK = 'WAITING_FOR_RESTOCK',
  PREPARATION_DONE = 'PREPARATION_DONE', // preparation done
  PREPARATION_DONE_INCOMPLETE = 'PREPARATION_DONE_INCOMPLETE', // preparation incomplete
  READY_TO_CLOSE = 'READY_TO_CLOSE',
  READY_FOR_COLLECT = 'READY_FOR_COLLECT',
  COLLECTED = 'COLLECTED',
  PENDING_COLLECTED_STEP1 = 'PENDING_COLLECTED_STEP1',
  PENDING_COLLECTED_STEP2 = 'PENDING_COLLECTED_STEP2',
  TO_CANCEL = 'TO_CANCEL',
  TO_CANCEL_FROM_IN_PROGRESS = 'TO_CANCEL_FROM_IN_PROGRESS',
  TO_CANCEL_FROM_PREPARATION_DONE = 'TO_CANCEL_FROM_PREPARATION_DONE',
  TO_CANCEL_FROM_PREPARATION_DONE_INCOMPLETE = 'TO_CANCEL_FROM_PREPARATION_DONE_INCOMPLETE',
  TO_CANCEL_FROM_READY_FOR_COLLECT = 'TO_CANCEL_FROM_READY_FOR_COLLECT',
  TO_CANCEL_FROM_WAITING_FOR_RESTOCK = 'TO_CANCEL_FROM_WAITING_FOR_RESTOCK',
  TO_CANCEL_FROM_READY_TO_CLOSE = 'TO_CANCEL_FROM_READY_TO_CLOSE',
  CANCELLED = 'CANCELLED',
  CANCELLED_FROM_IN_PROGRESS = 'CANCELLED_FROM_IN_PROGRESS',
  CANCELLED_FROM_PREPARATION_DONE = 'CANCELLED_FROM_PREPARATION_DONE',
  CANCELLED_FROM_PREPARATION_DONE_INCOMPLETE = 'CANCELLED_FROM_PREPARATION_DONE_INCOMPLETE',
  CANCELLED_FROM_READY_FOR_COLLECT = 'CANCELLED_FROM_READY_FOR_COLLECT',
  CANCELLED_FROM_WAITING_FOR_RESTOCK = 'CANCELLED_FROM_WAITING_FOR_RESTOCK',
  CANCELLED_FROM_READY_TO_CLOSE = 'CANCELLED_FROM_READY_TO_CLOSE',
  'PENDING_READY_TO_CLOSE_STEP1' = 'PENDING_READY_TO_CLOSE_STEP1',
  'PENDING_READY_TO_CLOSE_STEP2' = 'PENDING_READY_TO_CLOSE_STEP2',
  'PENDING_READY_TO_CLOSE_STEP2_B' = 'PENDING_READY_TO_CLOSE_STEP2_B',
  'PENDING_CANCELLED' = 'PENDING_CANCELLED',
  'PENDING_CANCELLED_TCPOS' = 'PENDING_CANCELLED_TCPOS',
  'PENDING_CANCELLED_OPERATOR' = 'PENDING_CANCELLED_OPERATOR',
  'PENDING_CANCELLED_SAP' = 'PENDING_CANCELLED_SAP',

  'ARCHIVED' = 'ARCHIVED',
  'BOARDING_PASS_CONFIRMED' = 'BOARDING_PASS_CONFIRMED',
  'SIGNED' = 'SIGNED',
}

export const statusBeforeReady = [
  OrderStatus.NEW,
  OrderStatus.IN_PROGRESS,
  OrderStatus.WAITING_FOR_RESTOCK,
  OrderStatus.PREPARATION_DONE,
  OrderStatus.PREPARATION_DONE_INCOMPLETE,
];

export const statusToCancel = [
  OrderStatus.TO_CANCEL,
  OrderStatus.TO_CANCEL_FROM_IN_PROGRESS,
  OrderStatus.TO_CANCEL_FROM_PREPARATION_DONE,
  OrderStatus.TO_CANCEL_FROM_PREPARATION_DONE_INCOMPLETE,
  OrderStatus.TO_CANCEL_FROM_READY_FOR_COLLECT,
  OrderStatus.TO_CANCEL_FROM_READY_TO_CLOSE,
  OrderStatus.TO_CANCEL_FROM_WAITING_FOR_RESTOCK,
];

export const statusCancelled = [
  OrderStatus.CANCELLED,
  OrderStatus.CANCELLED_FROM_IN_PROGRESS,
  OrderStatus.CANCELLED_FROM_PREPARATION_DONE,
  OrderStatus.CANCELLED_FROM_PREPARATION_DONE_INCOMPLETE,
  OrderStatus.CANCELLED_FROM_READY_FOR_COLLECT,
  OrderStatus.CANCELLED_FROM_WAITING_FOR_RESTOCK,
  OrderStatus.PENDING_CANCELLED,
  OrderStatus.PENDING_CANCELLED_OPERATOR,
  OrderStatus.PENDING_CANCELLED_SAP,
  OrderStatus.PENDING_CANCELLED_TCPOS,
];

export const statusCollected = [
  OrderStatus.COLLECTED,
  OrderStatus.PENDING_COLLECTED_STEP1,
  OrderStatus.PENDING_COLLECTED_STEP2,
];

export const statusReadyForCollect = [
  OrderStatus.READY_FOR_COLLECT,
  OrderStatus.BOARDING_PASS_CONFIRMED,
  OrderStatus.SIGNED,
];

export const statusIncomingCollect = [
  OrderStatus.NEW,
  OrderStatus.IN_PROGRESS,
  OrderStatus.WAITING_FOR_RESTOCK,
  OrderStatus.PREPARATION_DONE,
  OrderStatus.PREPARATION_DONE_INCOMPLETE,
  OrderStatus.READY_TO_CLOSE,
  OrderStatus.READY_FOR_COLLECT,
  OrderStatus.BOARDING_PASS_CONFIRMED,
  OrderStatus.SIGNED,
  OrderStatus.PENDING_READY_TO_CLOSE_STEP1,
  OrderStatus.PENDING_READY_TO_CLOSE_STEP2,
  OrderStatus.PENDING_READY_TO_CLOSE_STEP2_B,
];

export const statusAll = [];

export function isToCancel(orderStatus: OrderStatus): boolean {
  return orderStatus.startsWith(OrderStatus.TO_CANCEL);
}

export function isCancelled(orderStatus: OrderStatus): boolean {
  return orderStatus.startsWith(OrderStatus.CANCELLED);
}
