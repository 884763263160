import { OrdersData } from 'src/app/models/orders-data';

import { CarouselConfig } from './carousel-config';

export class RemoteDisplayUtils {
  /**
   * Carousel lazy loading: do we need to load more items
   * @param carouselPage (primeng carousel pages start with 0)
   */
  static shouldLoadMore(
    carouselPage: number,
    carouselConfig: CarouselConfig,
    ordersData: OrdersData,
  ): boolean {
    if (!ordersData.links?.next) {
      return false;
    }
    const lastElementIndexOnNextPage =
      (carouselPage + 1 + carouselConfig.numVisible) *
      carouselConfig.groupItems *
      carouselConfig.numScroll;
    const meta = ordersData.meta;
    const loadedOrders = meta.currentPage * meta.itemsPerPage;
    return lastElementIndexOnNextPage > loadedOrders;
  }

  static formatNewData(ordersData: OrdersData, previousData: OrdersData, push = false): OrdersData {
    const meta = ordersData.meta;
    let formattedOrdersData: OrdersData;
    if (push) {
      const previousItems = previousData.items;
      const orders = [...ordersData.items];
      formattedOrdersData = ordersData;
      formattedOrdersData.items = previousItems;
      formattedOrdersData.items.splice(
        (meta.currentPage - 1) * meta.itemsPerPage,
        meta.itemsPerPage,
        ...orders,
      );
    } else {
      formattedOrdersData = ordersData;
      if (formattedOrdersData.items.length < meta.totalItems) {
        formattedOrdersData.items = [
          ...formattedOrdersData.items,
          ...new Array(meta.totalItems - meta.itemsPerPage),
        ];
      }
    }
    // only for DEMO / DEBUG
    // formattedOrdersData.items.forEach((order, index) => {
    //   if (order) {
    //     (order as any).index = index + 1;
    //   }
    // });
    return formattedOrdersData;
  }
}
