import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-force-to-collect-dialog',
  templateUrl: './confirm-force-to-collect-dialog.component.html',
  styleUrls: ['./confirm-force-to-collect-dialog.component.scss'],
})
export class ConfirmForceToCollectDialogComponent {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  confirm(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }
}
