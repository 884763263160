import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-cancel-validation-order-dialog',
  templateUrl: './cancel-validation-order-dialog.component.html',
  styleUrls: ['./cancel-validation-order-dialog.component.scss'],
})
export class CancelValidationOrderDialogComponent {
  cancelValidationRef?: DynamicDialogRef;

  constructor(public ref: DynamicDialogRef) {}

  cancelOrder(): void {
    this.ref.close('cancelOrder');
  }

  back() {
    this.ref.close('back');
  }
}
