import { Injectable } from '@angular/core';

function parseValue(rawValue: any) {
  try {
    return JSON.parse(rawValue);
  } catch (err) {
    return rawValue;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private prefix = 'eretail_adp.';
  private userPrefix: string;

  // set by authenticationService
  setUserPrefix(userPrefix: string) {
    this.userPrefix = userPrefix;
  }

  set<T>(key: string, value: T): void {
    return localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(value));
  }

  get<T>(key: string): T {
    return parseValue(localStorage.getItem(`${this.prefix}${key}`));
  }

  setForUser<T>(key: string, value: T): void {
    return this.set(`${this.userPrefix}.${key}`, value);
  }

  getForUser<T>(key: string): T {
    return this.get(`${this.userPrefix}.${key}`);
  }

  delete(key: string): void {
    return localStorage.removeItem(`${this.prefix}${key}`);
  }
}
