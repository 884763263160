<div class="main-container">
  <ng-container *ngIf="ol; else loading">
    <header class="details-header">
      <div class="header-left">
        <h1 class="header-title">{{'ol-details.header.ol-id'|translate: {olId: ol.replenishmentSAPCode || ''}
          }}</h1>
        <p class="order-creation-date">{{'common.creation-date:'|translate}} {{ol.createdOn|date}}
          {{'common.at'|translate}}
          {{ol.createdOn|time}}
        </p>
      </div>
      <div class="header-right">
        <p>{{'common.status:'|translate}} <strong>{{'ro-status.SENT' | translate}}</strong></p>
        <p>{{'ol-details.header.ol-status:'|translate}} <strong>{{'ro-sap-status.' + ol.replenishmentSAPStatus|
          translate}}</strong></p>
      </div>
    </header>

    <p-table *ngIf="ol.roProductLines" [value]="ol.roProductLines" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'ol-details.columns.designation'|translate}}</th>
          <th>{{'ol-details.columns.sku'|translate}}</th>
          <th>{{'ol-details.columns.requestedQty'|translate}}</th>
          <th>{{'ol-details.columns.registeredQty'|translate}}</th>
          <th>{{'ol-details.columns.orders'|translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ropl let-i="rowIndex">
        <tr>
          <td>
            {{ropl.product.productJsonDataV2.aelmag_name?.labels | i18nLabel}}
          </td>
          <td>
            {{ropl.product.productSku}}
          </td>
          <td>
            {{ropl.quantity}}
          </td>
          <td>
            {{ropl.quantity}}
          </td>
          <td>
            <ng-container *ngFor="let order of ropl.orders; let last = last">
              <a class="link" [routerLink]="['/order/', order.orderId ]">{{order.orderExternalIdentifier}}</a>
              <ng-container *ngIf="!last">,</ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </ng-container>

  <footer class="details-footer">
    <button pButton type="button" class="p-button-outlined" (click)="back()">{{'ctas.quit'|translate}}</button>
  </footer>
</div>
<ng-template #loading>
  <app-full-page-spinner></app-full-page-spinner>
</ng-template>
