<div class="p-dialog-header">
  <span class="p-dialog-title">{{'dialog.specify-storage.title'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="specify-storage-form">
  <div class="p-dialog-content">
    <input class="login" type="text" formControlName="storage" pInputText
      [placeholder]="'dialog.specify-storage.placeholder'|translate" autocomplete="login" />
  </div>
  <div class="p-dialog-footer ctas">
    <button pButton type="submit" [label]="'dialog.specify-storage.save'|translate">
    </button>
    <button pButton type="button" class="p-button-outlined" (click)="discard()"
      [label]="'dialog.specify-storage.discard'|translate">
    </button>
  </div>
</form>
