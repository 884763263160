<div class="p-dialog-header">
  <span class="p-dialog-title">{{'Promotion'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<div class="p-dialog-content">
  <p *ngFor="let promotion of promotions">{{promotion.discountLabel}}</p>
</div>
