import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TreeTableModule } from 'primeng/treetable';

import { PreventDoubleClickDirective } from '../directives';
import { DatePipe } from '../pipes/date.pipe';
import { I18nLabelPipe } from '../pipes/i18n-label.pipe';
import { PluralTranslatePipe } from '../pipes/i18n-plural.pipe';
import { PricePipe } from '../pipes/price.pipe';
import { SlugifyPipe } from '../pipes/slugify.pipe';
import { TimePipe } from '../pipes/time.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { TwoDigitsPipe } from '../pipes/two-digits.pipe';
import { AdminScannerDialogComponent } from './components/admin-scanner-dialog/admin-scanner-dialog.component';
import { DialogComponent } from './components/dialog/dialog/dialog.component';
import { FlightNumberAndGateComponent } from './components/flight-number-and-gate/flight-number-and-gate.component';
import { TimeFromNowComponent } from './components/time-from-now/time-from-now.component';

const components = [DialogComponent];
@NgModule({
  declarations: [
    DatePipe,
    TimePipe,
    PricePipe,
    PluralTranslatePipe,
    I18nLabelPipe,
    SlugifyPipe,
    TimeFromNowComponent,
    TwoDigitsPipe,
    TruncatePipe,
    PreventDoubleClickDirective,
    ...components,
    AdminScannerDialogComponent,
    FlightNumberAndGateComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    TableModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    DialogModule,
    DynamicDialogModule,
    ToastModule,
    ProgressSpinnerModule,
    InputNumberModule,
    MessageModule,
    TreeTableModule,
    ZXingScannerModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DatePipe,
    TimePipe,
    I18nLabelPipe,
    TabViewModule,
    TableModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    DialogModule,
    PricePipe,
    DynamicDialogModule,
    ToastModule,
    ProgressSpinnerModule,
    InputNumberModule,
    InputSwitchModule,
    MessageModule,
    TreeTableModule,
    SlugifyPipe,
    TimeFromNowComponent,
    TwoDigitsPipe,
    TruncatePipe,
    PreventDoubleClickDirective,
    FlightNumberAndGateComponent,
  ],
})
export class SharedModule {}
