import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { isToCancel } from 'src/app/constants/order-status';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.scss'],
})
export class CancelOrderDialogComponent implements OnInit {
  order: Order;
  force = false; // admin mode

  get auto(): boolean {
    return isToCancel(this.order.orderStatus);
  }

  constructor(public ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.order = this.config.data?.order;
    this.force = this.config.data?.force;
  }

  cancelOrder(): void {
    this.ref.close('cancel');
  }

  forceCancelOrder(): void {
    this.ref.close('force-cancel');
  }

  close(): void {
    this.ref.close(null);
  }
}
