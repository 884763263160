<div class="p-dialog-header">
  <span class="p-dialog-title">{{'dialog.sku.title'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
      class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<div class="p-dialog-content">
  <p-progressSpinner *ngIf="!product?.productJsonDataV2"></p-progressSpinner>
  <img *ngIf="product?.productJsonDataV2 && imageUrl" width="180" height="180" [src]="imageUrl" alt="">
  <dl class="sku-description" *ngIf="product?.productJsonDataV2">
    <dt>{{'dialog.sku.sku'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_sku}}</dd>
    <dt>{{'dialog.sku.brand'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_manufacturer?.labels| i18nLabel}}</dd>
    <dt>{{'dialog.sku.web-designation'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_name?.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.sap-designation'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_erp_name}}</dd>
    <dt>{{'dialog.sku.capacity'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_capacity?.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.sub-brand'|translate}}</dt>
    <dd>{{product.productJsonDataV2.franchise.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.ean'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_ean}}</dd>
    <dt>{{'dialog.sku.ean-secondary'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_ean_secondary}}</dd>
    <dt>{{'dialog.sku.category'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_groupe?.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.concentration'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_concentration?.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.hue'|translate}}</dt>
    <dd>{{product.productJsonDataV2.aelmag_hue2.labels | i18nLabel}}</dd>
    <dt>{{'dialog.sku.weight'|translate}}</dt>
    <dd>
      {{product.productJsonDataV2.aelmag_net_weight}}
      {{product.productJsonDataV2['aelmag_net_weight-unit']}}
    </dd>
    <dt>{{'dialog.sku.stock'|translate}}</dt>
    <dd class="dialog-sku-stock">
      <span *ngFor="let stock of orderline.stocks">{{stock.organizationCode}}: {{stock.stock}}</span>
    </dd>
  </dl>
</div>
