<span class="flight-number">
  {{ flightInfo.companyIataCode
  }}{{ flightInfo.flightNumber }}
</span>
<!-- gate OK -->
<ng-container *ngIf="flightInfo?.gate">
  <span class="flight-gate-separator">
    -
  </span>
  <span
    class="gate-number"
    [class.gate-number-has-changed]="gateHasChanged(flightInfo)"
    >{{ flightInfo?.gate }}</span
  >
</ng-container> 
<!-- gate unknown -->
<ng-container *ngIf="!flightInfo?.gate && gateNotAvailable(flightInfo)">
  <span class="flight-gate-separator">
    -
  </span>
  <span
    class="gate-number"
    >{{ 'common.unknown' | translate }}</span
  >
</ng-container>
<!-- gate KO -->
<ng-container *ngIf="!flightInfo?.gate && gateKo(flightInfo)">
  <span class="flight-gate-separator">
    -
  </span>
  <span
    class="gate-number gate-number-has-changed"
    >{{ 'common.ko' | translate }}</span
  >
</ng-container> 