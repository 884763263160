<div class="p-dialog-header">
  <span class="p-dialog-title">{{ 'dialog.cancel-order.title-warning' | translate }}</span>
  <div class="p-dialog-header-icons">
    <button
      (click)="back()"
      type="button"
      class="p-dialog-header-icon p-dialog-header-maximize p-link"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <p>{{ 'dialog.cancel-order.text-warning' | translate }}</p>
</div>

<div class="p-dialog-footer ctas d-f jc-sb">
  <button class="p-button-outlined" pButton type="button" (click)="back()">{{ 'dialog.confirm-delivery.cta-close' | translate }}</button>
  <button pButton type="button" (click)="cancelOrder()">
    {{ 'ctas.cancel-order' |translate}}
  </button>
</div>
