import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService implements OnDestroy {
  public history: string[] = [];
  private subscription: Subscription;

  constructor(private router: Router) {}

  init(): void {
    this.subscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.history.unshift(ev.urlAfterRedirects);
        this.history.slice(0, 20);
      }
    });
  }

  goBackToOrdersList(): void {
    if (!this.history.length) {
      this.goBack();
      return;
    }
    const index = this.history.findIndex((url) => /\/orders/.test(url));
    if (index === -1) {
      this.goBack();
      return;
    }
    this.history = this.history.slice(index);
    this.router.navigateByUrl(this.history.shift() ?? '');
  }

  goBack(): void {
    window.history.back(); // eslint-disable-line
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
