import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RosData } from '../models/ros-data';
import { HttpUtils } from '../utils/http.utils';
import { AuthenticationService } from './authentication.service';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class RosService {
  constructor(
    private http: HttpClient,
    private toastMessagesService: ToastMessagesService,
    private authenticationService: AuthenticationService,
  ) {}

  getAll(params: any = {}): Observable<RosData> {
    return this.http
      .get<RosData>(`${environment.apiURL}/replenishments/orders`, {
        params: HttpUtils.getParams({
          ...params,
          shop: this.authenticationService.shopsIds,
        }),
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getAllRemote(params: any = {}, directAuth: string): Observable<RosData> {
    return this.http
      .get<RosData>(`${environment.apiURL}/replenishments/remote/orders`, {
        params: HttpUtils.getParams(params),
        headers: this.getDirectAuthHeaders(directAuth),
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getAllFromLink(link: string): Observable<RosData> {
    return this.http
      .get<RosData>(`${environment.apiHost}${link}`)
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  private getDirectAuthHeaders(directAuth?: string): { [header: string]: string } | undefined {
    if (!directAuth) {
      return;
    }
    return {
      'x-api-key': directAuth,
      'x-device': 'external_screen',
    };
  }
}
