import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { catchError, switchMapTo, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HEADER_COUNT_REFRESH_TIMER } from '../constants/constants';
import { OrderCounts } from '../models/counts';
import { OrdersData } from '../models/orders-data';
import { ArrayUtil } from '../utils/array.util';
import { DateTimeUtils } from '../utils/datetime.utils';
import { HttpUtils } from '../utils/http.utils';
import { AuthenticationService } from './authentication.service';
import { OrderService } from './order.service';
import { OrderStateService } from './order-state.service';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  countsSubject = new ReplaySubject<OrderCounts>(1);
  countsSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private orderService: OrderService,
    private orderStateService: OrderStateService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
  ) {
    this.authenticationService.isLoggedOut.subscribe(() => this.countsSubscription?.unsubscribe());
  }

  getAllFromLink(link: string, directAuth?: string): Observable<OrdersData> {
    return this.http
      .get<OrdersData>(`${environment.apiHost}${link}`, {
        headers: this.getDirectAuthHeaders(directAuth),
      })
      .pipe(tap(this.parseOrdersData.bind(this)))
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getAll(params: any = {}, directAuth?: string): Observable<OrdersData> {
    if (!directAuth) {
      params = { shopCode: this.authenticationService.shopsCodes[0], ...params };
    }

    return this.http
      .get<OrdersData>(`${environment.apiURL}/orders`, {
        params: HttpUtils.getParams(params),
        headers: this.getDirectAuthHeaders(directAuth),
      })
      .pipe(tap(this.parseOrdersData.bind(this)))
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  parseOrdersData(orders: OrdersData) {
    orders.items.forEach((order) => {
      if (!order) {
        return;
      }
      Object.assign(order, this.orderStateService.getOrderStateAndCTA(order));
      order.paymentDeadline = this.orderService.getPaymentDeadline(order);
      order.staff = ArrayUtil.getLast(order.orderJsonLogs)?.who;
      if (
        order.orderFlightInfos[0] &&
        !order.orderFlightInfos[0].gate &&
        DateTimeUtils.isToday(order.orderFlightInfos[0].departureDateTime)
      ) {
        order.orderFlightInfos[0].gate = this.translateService.instant('common.unknown');
      }
    });
  }

  refreshCounts() {
    this.stopRefreshCounts();
    // ensure logged in
    if (!this.authenticationService.token) {
      return;
    }

    this.countsSubscription = timer(0, HEADER_COUNT_REFRESH_TIMER * 1000)
      .pipe(
        switchMapTo(
          this.http
            .get<OrderCounts>(`${environment.apiURL}/count_orders`, {
              params: { shopCode: this.authenticationService.shopsCodes[0] },
            })
            .pipe(
              catchError(() => {
                // catch error to keep the timer running
                return EMPTY;
              }),
            ),
        ),
      )
      .subscribe(this.countsSubject);
  }

  stopRefreshCounts() {
    this.countsSubscription?.unsubscribe();
  }

  getCounts(): Observable<OrderCounts> {
    return this.countsSubject
      .asObservable()
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  private getDirectAuthHeaders(directAuth?: string): { [header: string]: string } | undefined {
    if (!directAuth) {
      return;
    }
    return {
      'x-api-key': directAuth,
      'x-device': 'external_screen',
    };
  }
}
