import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'primeng/carousel';
import { SharedModule } from 'src/app/shared/shared.module';

import { LazyChunkedCarouselComponent } from './lazy-chunked-carousel/lazy-chunked-carousel.component';
import { RemoteDisplayComponent } from './remote-display.component';

const routes: Routes = [
  {
    path: '',
    component: RemoteDisplayComponent,
  },
];

@NgModule({
  declarations: [RemoteDisplayComponent, LazyChunkedCarouselComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    CarouselModule,
  ],
})
export class RemoteDisplayModule {}
