<div class="p-dialog-header">
  <span class="p-dialog-title">{{ 'dialog.signature.title' | translate }}</span>
  <div class="p-dialog-header-icons">
    <button
      (click)="close()"
      type="button"
      class="p-dialog-header-icon p-dialog-header-maximize p-link"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <p-progressSpinner *ngIf="!orderSignature?.data"></p-progressSpinner>
  <img [src]="orderSignature?.data" />
</div>
<div class="p-dialog-footer">
  <button
    pButton
    type="button"
    class="p-button-outlined"
    (click)="close()"
    [label]="'ctas.quit' | translate"
  ></button>
</div>
