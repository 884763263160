import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounce as _debounce } from 'lodash';
import { flatten as _flatten, omit as _omit } from 'lodash';
import { Order } from 'src/app/models/order';
import { ReplenishmentRequest } from 'src/app/models/replenishment-request';
import { RO } from 'src/app/models/ro';
import { HistoryService } from 'src/app/services/history.service';
import { I18nService } from 'src/app/services/i18n.service';
import { ReplenishmentService } from 'src/app/services/replenishment.service';
import { RoService } from 'src/app/services/ro.service';

@Component({
  selector: 'app-ro-details',
  templateUrl: './ro-details.component.html',
  styleUrls: ['./ro-details.component.scss'],
})
export class RoDetailsComponent {
  ro: RO;
  requests: ReplenishmentRequest[];
  isLocked = false; // if sent
  langFull = this.i18nService.getLangFull();

  constructor(
    private roService: RoService,
    private replenishmentService: ReplenishmentService,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private historyService: HistoryService,
  ) {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.roService.getById(id).subscribe((ro) => {
        this.ro = ro;
        this.requests = ro.replenishmentRequests;
      });
    } else {
      this.replenishmentService.getAll().subscribe((ro) => {
        this.ro = ro;
        this.parseRequestsFromOrders();
        // this.ro = ro;
        // this.isLocked = this.ro.status === ROStatus.SENT;
      });
    }
    this.updateRr = _debounce(this.updateRr, 700);
  }

  parseRequestsFromOrders() {
    this.requests = _flatten(
      this.ro.orders.map((o) =>
        o.requests.map((r) => ({ ...r, order: _omit(o, 'requests') as Order })),
      ),
    );
    let prevOrderId: string;
    this.requests.forEach((r) => {
      if (prevOrderId !== r.order.orderExternalIdentifier) {
        (r as any).firstRow = true;
        prevOrderId = r.order.orderExternalIdentifier;
      }
    });
  }

  deleteItem(item: ReplenishmentRequest): void {
    this.replenishmentService.delete(item.replenishmentRequestId).subscribe(() => {
      this.requests = this.requests?.filter((i) => item !== i);
    });
  }

  back() {
    this.historyService.goBackToOrdersList();
  }

  increment(rr: ReplenishmentRequest): void {
    rr.quantity++;
    this.updateRr(rr);
  }

  decrement(rr: ReplenishmentRequest): void {
    if (rr.quantity <= 1) {
      return;
    }
    rr.quantity--;
    this.updateRr(rr);
  }

  updateRr(rr: ReplenishmentRequest): void {
    this.replenishmentService
      .updateRequest(rr)
      .subscribe((updatedRr) => Object.assign(rr, updatedRr));
  }
}
