<div class="p-dialog-header">
  <span class="p-dialog-title">{{'dialog.preparation-not-finished.title'|translate}}</span>
  <div class="p-dialog-header-icons">
    <button (click)="close()" type="button" class="p-dialog-header-icon p-dialog-header-maximize p-link"><span
        class="p-dialog-header-close-icon pi pi-times"></span></button>
  </div>
</div>
<div class="p-dialog-content">
  <p>{{'dialog.preparation-not-finished.text'|translate}}</p>
</div>
<div class="p-dialog-footer ctas">
  <button pButton type="button" (click)="close()">
    {{'dialog.preparation-not-finished.cta-close'|translate}}
  </button>
</div>
