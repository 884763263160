import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrimeTemplate } from 'primeng/api';
import { Observable, switchMap, tap } from 'rxjs';
import { Order } from 'src/app/models/order';
import { OrdersData } from 'src/app/models/orders-data';
import { OrdersService } from 'src/app/services/orders.service';
import { ArrayUtil } from 'src/app/utils/array.util';

import { CarouselConfig } from '../carousel-config';
import { RemoteDisplayUtils } from '../remote-display.utils';

@Component({
  selector: 'app-lazy-chunked-carousel',
  templateUrl: './lazy-chunked-carousel.component.html',
  styleUrls: ['./lazy-chunked-carousel.component.scss'],
})
export class LazyChunkedCarouselComponent implements OnInit, AfterContentInit {
  ordersData: OrdersData;
  chunkedArray: Order[][];
  @Input() carouselConfig: CarouselConfig;
  @Input() refreshWhen: Observable<any>;
  @Input() displaySync: Observable<any>;
  @Input() requestParams: any;
  @Input() set page(p: number) {
    const numSlides = (this.ordersData?.meta.totalItems ?? 0) / this.carouselConfig.groupItems;
    const numCarouselPages =
      Math.ceil((numSlides - this.carouselConfig.numVisible) / this.carouselConfig.numScroll) + 1;
    this.realPage = p % (numCarouselPages || 1);
  }
  realPage = 0;

  titleTemplate: TemplateRef<any>;
  itemTemplate: TemplateRef<any>;
  atLeastOneIsUrgent = false;
  displayMore = false;
  shop: string;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  // emits when data received
  @Output() dataReceived: EventEmitter<any> = new EventEmitter();

  constructor(private ordersService: OrdersService, private activatedRoute: ActivatedRoute) {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;
        case 'title':
          this.titleTemplate = item.template;
          break;
        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  ngOnInit(): void {
    this.shop = this.activatedRoute.snapshot.queryParams.shop;
    if (!this.shop) {
      return;
    }
    this.requestParams.shop = this.shop;
    const demo = this.activatedRoute.snapshot.queryParams.demo;
    if (demo) {
      // show more results to demo carousels
      delete this.requestParams.orderStatus;
    }

    this.refreshWhen
      .pipe(
        switchMap(() => {
          return this.ordersService.getAll(this.requestParams, this.shop);
        }),
        tap({
          next: (data) => {
            this.dataReceived.emit(data);
            // force refresh: emtpy data
            this.ordersData = undefined as any as OrdersData;
            this.chunkedArray = [
              new Array(this.carouselConfig.groupItems),
              new Array(this.carouselConfig.groupItems),
            ];
          },
          // emit if error to prevent parent from waiting
          error: () => this.dataReceived.emit(null),
        }),
        // make sure carousel will refresh display (and restart from the begining)
        // delay(0),
        // delayWhen(() => this.displaySync),
      )
      .subscribe((d) => {
        this.onData(d);
        this.onCarouselPage({ page: 0 });
      });
  }

  onData(ordersData: OrdersData, push = false): void {
    this.ordersData = RemoteDisplayUtils.formatNewData(ordersData, this.ordersData, push);
    this.chunkedArray = ArrayUtil.groupByCount(
      this.ordersData.items,
      this.carouselConfig.groupItems,
    );
    this.atLeastOneIsUrgent = !!this.ordersData.items.find((o) => o?.orderPreparationIsDelayed);
    this.displayMore = this.ordersData.meta.totalItems > this.ordersData.meta.itemsPerPage;
  }

  onCarouselPage(event: any) {
    if (!this.ordersData) {
      // data refresh in progress
      return;
    }
    const shouldLoadMore = RemoteDisplayUtils.shouldLoadMore(
      event.page,
      this.carouselConfig,
      this.ordersData,
    );
    if (shouldLoadMore) {
      this.ordersService
        .getAllFromLink(this.ordersData.links.next, this.shop)
        .subscribe((d) => this.onData(d, true));
    }
  }
}
