import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit as _omit } from 'lodash';
import { DateTime } from 'luxon';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { BoardingPass, Order, OrderLog, OrderSignature } from '../models/order';
import { Orderline } from '../models/orderline';
import { PaymentDeadline } from '../models/payment-deadline';
import { OrderStateService } from './order-state.service';
import { ToastMessagesService } from './toast-messages.service';

export type OrderStatusEndpoint =
  | 'start-preparation'
  | 'continue-preparation'
  | 'finish-preparation'
  | 'collect'
  | 'cancel'
  | 'force-cancel'
  | 'force-collect'
  | 'force-to-collect'
  | 'reset-tcpos-order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private toastMessagesService: ToastMessagesService,
    private orderStateService: OrderStateService,
  ) {}

  getById(id: string): Observable<Order> {
    return this.http.get<Order>(`${environment.apiURL}/orders/${id}`).pipe(
      map((order) => ({
        ...order,
        ...this.orderStateService.getOrderStateAndCTA(order),
      })),
      tap({ error: (error) => this.toastMessagesService.httpError(error) }),
    );
  }

  getBoardingPassInformation(url: string): Observable<BoardingPass> {
    return this.http
      .get<BoardingPass>(`${environment.apiURL}/${url.replace('/api/', '')}`)
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getSignatureById(id: string): Observable<OrderSignature> {
    return this.http.get<OrderSignature[]>(`${environment.apiURL}/orders/${id}/signatures`).pipe(
      map((signatures) => signatures[0]),
      tap({ error: (error) => this.toastMessagesService.httpError(error) }),
    );
  }

  getPaymentDeadline(order: Order): PaymentDeadline {
    const paymentDeadline: PaymentDeadline = {
      expired: false,
    };
    if (order.orderDeadlineCashout) {
      const deadline = DateTime.fromISO(order.orderDeadlineCashout);
      const diff = deadline.diffNow(['days', 'hours', 'minutes']).toObject();
      if (diff) {
        Object.assign(paymentDeadline, {
          expired: (diff.days ?? -1) < 0,
          ...diff,
        });
      }
    }
    return paymentDeadline;
  }

  // only the `orderId` field is required in the `order` object
  update(order: Partial<Order> & Pick<Order, 'orderId'>): Observable<Order> {
    return this.http
      .patch<Order>(`${environment.apiURL}/orders/${order.orderId}`, _omit(order, ['orderId']))
      .pipe(
        map((updatedOrder) => ({
          ...updatedOrder,
          ...this.orderStateService.getOrderStateAndCTA(updatedOrder),
        })),
        tap({ error: (error) => this.toastMessagesService.httpError(error) }),
      );
  }

  updateStatus(orderId: string, endpoint: OrderStatusEndpoint, body = {}): Observable<Order> {
    return this.http.patch<Order>(`${environment.apiURL}/orders/${orderId}/${endpoint}`, body).pipe(
      map((updatedOrder) => ({
        ...updatedOrder,
        ...this.orderStateService.getOrderStateAndCTA(updatedOrder),
      })),
      tap({ error: (error) => this.toastMessagesService.httpError(error) }),
    );
  }

  updateOrderline(
    order: Order,
    orderline: Partial<Orderline> & Pick<Orderline, 'orderlineId'>,
  ): Observable<Orderline> {
    return this.http
      .patch<Orderline>(
        `${environment.apiURL}/orders/${order.orderId}/orderlines/${orderline.orderlineId}`,
        _omit(orderline, ['orderlineId']),
      )
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  addLogMessage(orderId: string, message: string): Observable<OrderLog> {
    return this.http
      .post<OrderLog>(`${environment.apiURL}/orders/${orderId}/orderlog`, {
        message,
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  switchInstoreStorage(order: Order): Observable<Order> {
    return this.http
      .patch<Order>(`${environment.apiURL}/orders/${order.orderId}/switch-instore-storage`, {})
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }
}
