export type DatetimeFormat =
  | 'time'
  | 'date'
  | 'dateInput'
  | 'dateTimeShort'
  | 'dateTimeShort2'
  | 'dateTimeFlightInfo';

export const DATE_TIME_FORMATS: { [key in DatetimeFormat]: { en: string; fr: string } } = {
  time: {
    en: 'HH:mm',
    fr: "HH'h'mm",
  },
  date: {
    en: 'LLL d, yyyy',
    fr: 'd LLL yyyy',
  },
  dateInput: {
    en: 'MM/dd/yyyy',
    fr: 'dd/MM/yyyy',
  },
  dateTimeShort: {
    en: 'dd/MM, HH:mm',
    fr: "dd/MM, HH'h'mm",
  },
  // remote display
  dateTimeShort2: {
    en: "MMM dd, 'at' HH:mm",
    fr: "dd MMM 'à' HH'h'mm",
  },
  dateTimeFlightInfo: {
    en: "d LLL yyyy HH'h'mm",
    fr: "d LLL yyyy HH'h'mm",
  },
};
