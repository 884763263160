import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigits',
})
export class TwoDigitsPipe implements PipeTransform {
  transform(value: number | string | undefined): string {
    const stringValue = `${value ?? ''}`;
    if (stringValue.length === 1) {
      return `0${stringValue}`;
    }
    return stringValue;
  }
}
