import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ReplenishmentRequest } from '../models/replenishment-request';
import { RO, RoProductLine } from '../models/ro';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class RoService {
  constructor(private http: HttpClient, private toastMessagesService: ToastMessagesService) {}

  getById(id: string): Observable<RO> {
    return this.http
      .get<RO>(`${environment.apiURL}/replenishments/orders/${id}`)
      .pipe(tap((ro) => (ro.roProductLines = this.parseRoProductLines(ro.replenishmentRequests))))
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  parseRoProductLines(replenishmentRequests: ReplenishmentRequest[]): RoProductLine[] {
    const products: { [sku: string]: RoProductLine } = {};
    replenishmentRequests.forEach((rr) => {
      if (!products[rr.productSku]) {
        products[rr.productSku] = {
          product: rr.product,
          quantity: rr.quantity,
          orders: [rr.order],
        };
      } else {
        products[rr.productSku].quantity += rr.quantity;
        products[rr.productSku].orders.push(rr.order);
      }
    });
    return Object.values(products);
  }
}
