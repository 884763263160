import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit as _omit } from 'lodash';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from '../models/user';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private toastMessagesService: ToastMessagesService) {}

  get(userId: string): Observable<User> {
    return this.http
      .get<User>(`${environment.apiURL}/users/${userId}`)
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  createAdmin(user: Partial<User> & Pick<User, 'login'>): Observable<User> {
    return this.http
      .post<User>(`${environment.apiURL}/users/add-admin`, {
        roles: ['ROLE_ADMIN'],
        // inactive: true,
        // timezone: 'Europe/Paris',
        language: 'en',
        ...user,
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  update(user: Partial<User> & Pick<User, 'id'>): Observable<User> {
    return this.http
      .patch<User>(`${environment.apiURL}/users/${user.id}`, _omit(user, ['id']))
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  delete(user: Partial<User> & Pick<User, 'id'>): Observable<User> {
    return this.http
      .delete<User>(`${environment.apiURL}/users/${user.id}`)
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }
}
