import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { omit as _omit } from 'lodash';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RoSapStatus } from '../constants/ro-sap-status';
import { Order } from '../models/order';
import { Orderline } from '../models/orderline';
import { ReplenishmentRequest } from '../models/replenishment-request';
import { ReplenishmentRequestStatus } from '../models/replenishment-request-status';
import { RO } from '../models/ro';
import { AuthenticationService } from './authentication.service';
import { ToastMessagesService } from './toast-messages.service';

@Injectable({
  providedIn: 'root',
})
export class ReplenishmentService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private toastMessagesService: ToastMessagesService,
  ) {}

  get(replenishmentRequestId: string): Observable<ReplenishmentRequest> {
    return this.http
      .get<ReplenishmentRequest>(
        `${environment.apiURL}/replenishments/requests/${replenishmentRequestId}`,
      )
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  delete(replenishmentRequestId: string): Observable<ReplenishmentRequest> {
    return this.http
      .delete<ReplenishmentRequest>(
        `${environment.apiURL}/replenishments/requests/${replenishmentRequestId}`,
      )
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getAll(): Observable<RO> {
    return this.http
      .get<RO>(`${environment.apiURL}/pending_requests`, {
        params: { shop: this.authenticationService.shopsIds },
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  replenish(orderline: Orderline, order: Order): Observable<ReplenishmentRequest> {
    return this.http
      .post<ReplenishmentRequest>(`${environment.apiURL}/replenishments/requests`, {
        shop: `/api/shops/${order.shopId}`,
        product: `/api/products/${orderline.productSku}`,
        order: `/api/orders/${order.orderId}`,
        quantity: orderline.productOrderedQty,
      })
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  updateOrder(order: Order, replenishmentRequests: ReplenishmentRequest[]): Order {
    (replenishmentRequests ?? []).forEach((rr) => {
      const orderline = order.orderlinesJsonData.find((ol) => ol.productSku === rr.productSku);
      if (orderline) {
        orderline.replenishmentRequest = rr;
      } else {
        this.toastMessagesService.warn(
          `Replenishment request did not find associated product (productSku=${rr.productSku})`,
        );
      }
    });
    return order;
  }

  updateRequest(
    rr: Partial<ReplenishmentRequest> & Pick<ReplenishmentRequest, 'replenishmentRequestId'>,
  ): Observable<ReplenishmentRequest> {
    return this.http
      .patch<ReplenishmentRequest>(
        `${environment.apiURL}/replenishments/requests/${rr.replenishmentRequestId}`,
        _omit(rr, ['replenishmentRequestId', 'product', 'order', 'replenishmentOrder']),
      )
      .pipe(tap({ error: (error) => this.toastMessagesService.httpError(error) }));
  }

  getReplenishmentRequestStatus(rr: ReplenishmentRequest): ReplenishmentRequestStatus {
    if (!rr) {
      return 'none';
    } else if (rr.replenishmentRequestStatus === RoSapStatus.SAP_STATUS_NONE) {
      return 'created';
    } else if (!rr || !rr.replenishmentOrderId) {
      return 'none';
    } else if (rr.replenishmentOrder?.replenishmentSAPCode) {
      return 'sent';
    }
    return 'none';
  }
}
