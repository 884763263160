import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { DATE_TIME_FORMATS, DatetimeFormat } from '../constants/datetime-formats';

@Pipe({
  name: 'date',
})
export class DatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: string | Date | undefined | boolean,
    formatKey: DatetimeFormat = 'date',
  ): string {
    if (!value || typeof value == 'boolean') {
      return '';
    }
    let dateTime: DateTime;
    if (value instanceof Date) {
      dateTime = DateTime.fromJSDate(value);
    } else {
      dateTime = DateTime.fromISO(value.replace(' ', 'T'));
    }
    const format =
      DATE_TIME_FORMATS[formatKey][(this.translateService.currentLang as 'en' | 'fr') ?? 'en'];
    return dateTime.setLocale(this.translateService.currentLang).toFormat(format);
  }
}
